import revive_payload_client_hgbmWCpyfb from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EuvGq3XXAs from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZPk1QgilI8 from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_bLqHlHvUg6 from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_sass@1.6_2ohwgzdgyoilg3225pxyj6elfm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_0D7pNWUdlr from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2bTByT12iD from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Ab11r8Sh6F from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2fLINJ8ylG from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/OptiLynx/marketing/.nuxt/components.plugin.mjs";
import prefetch_client_QDsYrIRZqV from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.43_@upstash+redis@1.34.0_eslint@8.57.0_io_hslzeztscxkfqdci7pbsboxe4i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_3XzKihCgSL from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.4_saadnbx3ovbc6kpmolc7ebvfpa/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_wlbTTFRntF from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.4_saadnbx3ovbc6kpmolc7ebvfpa/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_iUTvJMQ1dQ from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_sass@_m45ikedm2nuc7jtxyxpw2fabu4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_4NWsmr24Am from "/builds/OptiLynx/marketing/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_sass@_m45ikedm2nuc7jtxyxpw2fabu4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_bQPSrP6KxU from "/builds/OptiLynx/marketing/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.6_magicast@0.3.4_rollup@4.20.0_webpack@5.93.0_esbuild@0.23.0_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import slideovers_1L2FVmY03u from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+ui@2.18.3_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_pnxchsbdpiu4tagn3nl3ph3f7q/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_ULM7waCDVX from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+ui@2.18.3_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_pnxchsbdpiu4tagn3nl3ph3f7q/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_FiHEXF7J2d from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+ui@2.18.3_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_pnxchsbdpiu4tagn3nl3ph3f7q/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_kMFpnxgd3E from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_9UTIr59XLu from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.3.5_@types+node@18.19.43_sass@1.63.6_ter_4uru2ks4hac2djayzf5ffqo4ka/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_EmYcWZMaP9 from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.1.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_WwZIsqqQtp from "/builds/OptiLynx/marketing/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.1.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import simple_lightbox_client_vigHnfRDDP from "/builds/OptiLynx/marketing/plugins/simple-lightbox.client.ts";
export default [
  revive_payload_client_hgbmWCpyfb,
  unhead_EuvGq3XXAs,
  router_ZPk1QgilI8,
  _0_siteConfig_bLqHlHvUg6,
  payload_client_0D7pNWUdlr,
  navigation_repaint_client_2bTByT12iD,
  check_outdated_build_client_Ab11r8Sh6F,
  chunk_reload_client_2fLINJ8ylG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QDsYrIRZqV,
  titles_3XzKihCgSL,
  defaults_wlbTTFRntF,
  siteConfig_iUTvJMQ1dQ,
  inferSeoMetaPlugin_4NWsmr24Am,
  plugin_bQPSrP6KxU,
  slideovers_1L2FVmY03u,
  modals_ULM7waCDVX,
  colors_FiHEXF7J2d,
  plugin_client_kMFpnxgd3E,
  plugin_9UTIr59XLu,
  switch_locale_path_ssr_EmYcWZMaP9,
  i18n_WwZIsqqQtp,
  simple_lightbox_client_vigHnfRDDP
]