
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "sk",
  "en"
]

export const localeLoaders = {
  "cs": [{ key: "../lang/translations/cs.json", load: () => import("../lang/translations/cs.json" /* webpackChunkName: "locale__builds_OptiLynx_marketing_lang_translations_cs_json" */), cache: true }],
  "sk": [{ key: "../lang/translations/sk.json", load: () => import("../lang/translations/sk.json" /* webpackChunkName: "locale__builds_OptiLynx_marketing_lang_translations_sk_json" */), cache: true }],
  "en": [{ key: "../lang/translations/en.json", load: () => import("../lang/translations/en.json" /* webpackChunkName: "locale__builds_OptiLynx_marketing_lang_translations_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../lang/i18n.config.ts?hash=980b4b1d&config=1" /* webpackChunkName: "__lang_i18n_config_ts_980b4b1d" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./lang/i18n.config.ts",
  "locales": [
    {
      "code": "cs",
      "name": "Čeština",
      "files": [
        "lang/translations/cs.json"
      ]
    },
    {
      "code": "sk",
      "name": "Slovenština",
      "files": [
        "lang/translations/sk.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "files": [
        "lang/translations/en.json"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./lang/translations",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "pricing": {
      "cs": "/cenik"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "name": "Čeština",
    "files": [
      {
        "path": "lang/translations/cs.json"
      }
    ]
  },
  {
    "code": "sk",
    "name": "Slovenština",
    "files": [
      {
        "path": "lang/translations/sk.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "lang/translations/en.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
