<script setup lang="ts">
const props = defineProps<{
  disabled?: boolean
  dark?: boolean
}>()
</script>

<template>
  <button :disabled="props.disabled"
    class="disabled:bg-neutral-400 disabled:cursor-not-allowed rounded-xl bg-optilynx-pink px-3 py-2 text-white transition duration-300 hover:bg-optilynx-pink-hover"
    :class="{ '!bg-optilynx-purple hover:!bg-optilynx-purple-hover': props.dark }"
  >
    <slot name="default" />
  </button>
</template>
