<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
      <CookieControl :locale="locale" />
    </NuxtLayout>
  </div>
</template>

<script setup>
const { locale } = useI18n()

if (process.client) {
  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    window.dataLayer.push(arguments)
  }

  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  })
}

function analyticsConsent() {
  gtag('consent', 'update', {
    analytics_storage: 'granted',
  })
}

function adConsent() {
  gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
  })
}

const { cookiesEnabledIds } = useCookieControl()

watch(
  () => cookiesEnabledIds.value,
  (newValue, oldValue) => {
    if (
      !oldValue?.includes('google-analytics') &&
      newValue?.includes('google-analytics')
    ) {
      analyticsConsent()
    }
    if (!oldValue?.includes('google-ads') && newValue?.includes('google-ads')) {
      adConsent()
    }
  }
)

useHead({
  htmlAttrs: {
    lang: locale.value,
  },
  title: 'Skladový systém Atlantis',
  script: [
    {
      textContent: `(function (w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s)
  const dl = l !== 'dataLayer' ? '&l=' + l : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
})(window, document, 'script', 'dataLayer', 'GTM-NJGDHTZ3')`,
      tagPosition: 'head',
    },
  ],
})

watch(
  () => cookiesEnabledIds.value,
  (newValue, oldValue) => {
    if (
      !oldValue?.includes('google-analytics') &&
      newValue?.includes('google-analytics')
    ) {
      analyticsConsent()
    }
    if (!oldValue?.includes('google-ads') && newValue?.includes('google-ads')) {
      adConsent()
    }
  }
)
</script>

<style>
@import 'assets/css/cookie.scss';

::selection {
  @apply bg-optilynx-pink text-white;
}
</style>
