import SimpleLightbox from 'simplelightbox/src/simple-lightbox'
export default defineNuxtPlugin(() => {
  return {
    provide: {
      simpleLightbox: (selector: string): SimpleLightbox =>
        new SimpleLightbox(selector, {
          overlayOpacity: 0.90,
          captions: false,
          uniqueImages: false,
          showCounter: false,
        }),
    },
  }
})
